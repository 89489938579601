var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:_vm.$style.card},[_c('Filters',{on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{staticClass:"mb-0",attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.table,attrs:{"headers":_vm.filtered_cols,"items":_vm.aplications,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.events_loading,"hide-default-footer":"","no-data-text":"Заявки, удовлетворяющие выбранным параметрам, отсутствуют"},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_c('ChipStatus',{attrs:{"status":_vm.aplication_chip_status(item)}},[_vm._v(" "+_vm._s(item.status.name)+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")])]}},{key:"item.all_events_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.all_events_count)+" ")])]}},{key:"item.approval_events_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.approval_events_count)+" ")])]}},{key:"item.revision_events_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.revision_events_count)+" ")])]}},{key:"item.canceled_events_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.canceled_events_count)+" ")])]}},{key:"item.completed_events_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.completed_events_count)+" ")])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],2),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false},"default_limit":_vm.default_limit},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }